import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";

// Dashboard

import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";
// import PrivateDashboard from "./Components/DashBoard/PrivateDashboard/PrivateDashboard";
// import Order from "./Components/DashBoard/Order/Order";

import { Context } from "./utils/context";
import SettingContent from "./Components/Masters/SettingContent/SettingContent";
// import CupponCodes from "./Components/Masters/CupponCodes/Tables";

import Role from "./Components/EmployeeManagement/Role/Table";
import EmployeeDetails from "./Components/EmployeeManagement/EmployeeDetails/Table";
import EmployeeRequests from "./Components/EmployeeManagement/EmployeeRequests/Table";
import CustomerDetails from "./Components/EmployeeManagement/CustomerDetails/Table";
import ContactUs from "./Components/CustomerManagement/ContactUSList/Tables";
import Product from "./Components/Products/Product/Table";
import Sales from "./Components/Products/Sales/Tables";
import ProductType from "./Components/Products/producType/Tables";
import WhyChooseUs from "./Components/AboutUs/WhyChooseUs/Tables";
import HappyCustomers from "./Components/AboutUs/HappyCustomers/Tables";
import OurStory from "./Components/AboutUs/OurStory/Tables";
import AboutBanner from "./Components/AboutUs/AboutBanner/Tables";

import Orders from "./Components/OrderManagments/Orders/Table";
import OrdersToRefund from "./Components/OrderManagments/OrderToRefund/Table";
import ReturnOrders from "./Components/OrderManagments/ReturnOrders/Table";

// Settings 
// Categories
import Category from "./Components/Masters/Category/Tables"
import SubCategory from "./Components/Masters/Sub_Category/Tables"
import ChildSubCategory from "./Components/Masters/Child_Sub_Category/Tables"
import Attributes from "./Components/Masters/Attribute/Tables"
import SubAttributes from "./Components/Masters/SubAttribute/Tables"

// Customization 
import WebHeader from "./Components/Masters/WebHeader/Tables"
import AppSetup from "./Components/Masters/AppSetup/Tables"
import HomeBanner from "./Components/Masters/S_Banner/Tables"
import Blogs from "./Components/Masters/Blogs/Tables"
import FaqCategory from "./Components/Masters/FaqCategory/Tables"
import Faq from "./Components/Masters/FAQs/Tables"
import BannerTwo from "./Components/Masters/BannerTwo/Tables"
import OurPartner from "./Components/Masters/OurPartner/Tables"

import CouponCodes from "./Components/Masters/CouponCodes/Table"
import Brands from "./Components/Masters/Brands/Tables"

// Location 
import Country from "./Components/Masters/Country/Tables"
import State from "./Components/Masters/State/Tables"
import City from "./Components/Masters/City/Tables"
import Pincode from "./Components/Masters/Pincode/Tables"
import CountryCodes from "./Components/Masters/CountryCode/Tables"

// Tax 
import TaxType from "./Components/Masters/Tax_Types/Tables"
import TaxPercentage from "./Components/Masters/Tax_Percentage/Tables"


// Seller

const App = () => {
  const location = useLocation();
  const { isSidebarOpen } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);

  const isLoginPage = location.pathname === "/";

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <div
      className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
    >
      {isLoginPage ? (
        <Login />
      ) : (
        <div className=" d-flex sidebar-x-scroll">
          <div
            className={`${isSidebarOpen
              ? "sidebarHeadopen "
              : "scale-in-hor-left sidebarHeadClose"
              }  pe-0`}
          >
            <Sidebar />
          </div>
          <div
            className={`${isSidebarOpen
              ? "dashboradopen"
              : "scale-in-hor-right dashboradopen"
              }  ps-0`}
          >
            <div className="allRoutesMain">
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/Header" element={<Header />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/advanceDashboard"
                  element={<AdvanceDashboard />}
                />
                <Route path="/settingContent" element={<SettingContent />} />
                <Route path="/employee/role" element={<Role />} />
                <Route
                  path="/employee/employee-details"
                  element={<EmployeeDetails />}
                />
                <Route
                  path="/employee/employee-requests"
                  element={<EmployeeRequests />}
                />
                <Route
                  path="/employee/customer-details"
                  element={<CustomerDetails />}
                />
                <Route
                  path="/employee/contact-us"
                  element={<ContactUs />}
                />
                <Route path="/product" element={<Product />} />
                <Route path="/sales" element={<Sales />} />
                <Route path="/type-product" element={<ProductType />} />
                <Route path="/product/:id" element={<Product />} />
                <Route path="/about-us/why-choose-us" element={<WhyChooseUs />} />
                <Route path="/about-us/happy-customers" element={<HappyCustomers />} />
                <Route path="/about-us/our-story" element={<OurStory />} />
                <Route path="/about-us/about-banner" element={<AboutBanner />} />
                <Route path="/order-managements/order" element={<Orders />} />
                <Route path="/why_choose_us" element={<WhyChooseUs />} />
                <Route path="/order-managements/orders" element={<Orders />} />
                <Route path="/order-managements/order/refund" element={<OrdersToRefund />} />
                <Route path="/order-managements/order/return" element={<ReturnOrders />} />

                {/* ******************************     Settings   ********************************** */}

                {/* **********************   Categories   ******************************* */}
                <Route path="/settings/category" element={<Category />} />
                <Route path="/settings/sub-category" element={<SubCategory />} />
                <Route path="/settings/child-sub-category" element={<ChildSubCategory />} />
                <Route path="/settings/attributes" element={<Attributes />} />
                <Route path="/settings/sub-attributes" element={<SubAttributes />} />

                {/* **********************   Customization   ******************************* */}
                <Route path="/settings/customization/web-header" element={<WebHeader />} />
                <Route path="/settings/customization/app-setup" element={<AppSetup />} />
                <Route path="/settings/customization/home-banner" element={<HomeBanner />} />
                <Route path="/settings/customization/blogs" element={<Blogs />} />
                <Route path="/settings/customization/faq-category" element={<FaqCategory />} />
                <Route path="/settings/customization/faqs" element={<Faq />} />
                <Route path="/settings/customization/banner-two" element={<BannerTwo />} />
                <Route path="/settings/customization/our-partner" element={<OurPartner />} />

                <Route path="/settings/Coupon-codes" element={<CouponCodes />} />
                <Route path="/settings/brands" element={<Brands />} />

                {/* **********************    Location  ******************************* */}

                <Route path="/settings/location/country" element={<Country />} />
                <Route path="/settings/location/state" element={<State />} />
                <Route path="/settings/location/city" element={<City />} />
                <Route path="/settings/location/pincode" element={<Pincode />} />
                <Route path="/settings/location/country-codes" element={<CountryCodes />} />

                {/* **********************    Tax  ******************************* */}
                <Route path="/settings/tax/tax-type" element={<TaxType />} />
                <Route path="/settings/tax/tax-percentage" element={<TaxPercentage />} />

              </Routes>
            </div>
            {/* <Home /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
