import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";

library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const GetEditData = async () => {
      try {
        const response = await getData(`/home/banner/${id}`);
        reset(response?.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    GetEditData();
  }, [id, getData, reset]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setValue("image", file);
    }
  };

  const onSubmit = async (data) => {
    try {
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("image", data?.image[0]);
      const response = await postData(`/home/banner/${id}`, finalData);
      if (response?.success) {
        setShowModal({ code: response.code, message: response.message });
        window.location.reload();
      } else {
        setShowModal({ code: response.code, message: response.message });
        window.location.reload();
      }

      setTimeout(() => {
        setShowModal({ code: 0, message: "" });
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log("Error submitting form:", error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Banner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Banner</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Banner"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label> Image</Form.Label>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        onChange={handleImageChange}
                        type="file"
                        {...register("image", {})}
                        multiple={false}
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Preview</Form.Label>
                    <div className="preview-container mt-3">
                      {imageFile &&
                        (imageFile.type.startsWith("video/") ? (
                          <video
                            src={URL.createObjectURL(imageFile)}
                            controls
                            className="video-preview"
                            style={{ width: "100%", height: "auto" }}
                          />
                        ) : (
                          <img
                            src={URL.createObjectURL(imageFile)}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100%", height: "auto" }}
                          />
                        ))}
                      {!imageFile &&
                        getValues("image") &&
                        typeof getValues("image") === "string" &&
                        (getValues("image").endsWith(".mp4") ||
                        getValues("image").endsWith(".avi") ||
                        getValues("image").endsWith(
                          ".mov"
                        ) /* Add more video formats as needed */ ? (
                          <video
                            src={IMG_URL + getValues("image")}
                            controls
                            className="video-preview"
                            style={{ width: "100%", height: "auto" }}
                          />
                        ) : (
                          <img
                            src={IMG_URL + getValues("image")}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100%", height: "auto" }}
                          />
                        ))}
                    </div>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
