import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";
import { AddButton, EditButton, DeletButton } from "../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import SettingContent from "../SettingContent/SettingContent";
import ModelBulkUpload from "../../common/ModelBulkUpload";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";
library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    isAllow,
    Select2Data,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();

  const [changeStatus, setChangeStatus] = useState();
  const [type, setType] = useState([]);
  const [searchType, setSearchType] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/masters/tax-percentage?page=${currentPage}&per_page=${perPage}&term=${search}&searchType=${
        searchType?.value || ""
      }`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };
  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1, search, searchType]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/masters/tax-percentage/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/masters/tax-percentage/${recordToDeleteId}`
      );
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [showoff, setShowoff] = useState(false);

  const BulkUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const response = await postData(
        "/masters/tax-percentage/bulk",
        formData,
        {}
      );
      if (response?.success) {
        setShowModal({ code: response.code, message: response.message });
      } else {
        setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        setShowoff(response?.data);
      }, 1000);
      getDataAll();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const Sample = async (e) => {
    try {
      await getDownloadDataExcel("/masters/tax-percentage/sample", {}, "state");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const GetAllCategory = async () => {
    const response = await getData("/all-tax-type");

    if (response?.success) {
      setType(await Select2Data(response?.data, "tax_type_id"));
    }
  };

  useEffect(() => {
    GetAllCategory();
  }, []);

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Tax Percentage"} link={"/masters/tax-percentage"} />
        <div className="row">
          <section className="AdvanceDashboard">
            <div className="col-xxl-12 col-xl-12  ">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio mt-3">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="d-flex">
                        <div className="add me-3" onClick={() => handleShow()}>
                          {isAllow?.includes(58) ? (
                            <Link
                              // to="/masters/sub-category/add"
                              type="button"
                              className="btn btn-add pe-3"
                            >
                              <div>
                                <img
                                  src={plus}
                                  className="plus me-2 ms-0"
                                  alt=""
                                />
                                Add Tax Percentage
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="add me-3">
                          <div className="dropdown">
                            <button
                              className="btn btn-columns dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={colunms} className="columns " alt="" />
                              Column Selection
                              <img src={top} className="top ms-1" alt="" />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col1")
                                  }
                                  href="#"
                                >
                                  Sr. No.
                                  {visible?.col1 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col2")
                                  }
                                  href="#"
                                >
                                  Tax Type
                                  {visible?.col2 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col3")
                                  }
                                  href="#"
                                >
                                  Tax Percentage
                                  {visible?.col3 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col4")
                                  }
                                  href="#"
                                >
                                  Status
                                  {visible?.col4 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col5")
                                  }
                                  href="#"
                                >
                                  Action
                                  {visible?.col5 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>

                        {/* {isAllow?.includes(212) ? (
                          <div className="add me-3">
                            <button
                              className="btn btn-add pe-3"
                              onClick={() =>
                                document.getElementById("StateFile").click()
                              }
                            >
                              Bulk Upload
                            </button>
                            <input
                              type="file"
                              id="StateFile"
                              onChange={(e) => {
                                BulkUpload(e);
                              }}
                              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              style={{ display: "none" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {isAllow?.includes(212) ? (

                          <div className="add me-3">
                            <Link type="button" className="btn btn-add pe-3">
                              <div onClick={Sample}>Sample</div>
                            </Link>
                          </div>
                        ) : (
                          <></>
                        )} */}
                      </div>
                      <div className="border-line mt-3"></div>
                      <div className="row mt-4">
                        <div className="  col-lg-4 col-md-12 col-12">
                          <div className="d-flex">
                            <div className="show me-2">
                              <p className="show mt-1">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries">
                              <p className="show mt-1">entries</p>
                            </div>
                          </div>
                        </div>
                        <div className="  col-lg-8  col-md-12 col-12">
                          <div className="d-flex justify-content-end">
                            <div className="sowing me-2">
                              <p className="show mt-2">{`Showing ${Math.min(
                                (currentPage - 1) * perPage + 1
                              )} to ${Math.min(
                                currentPage * perPage,
                                data?.data?.totalEntries
                              )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>
                            <div
                              className="num me-2"
                              style={{ width: "300px" }}
                            >
                              <Select
                                isSearchable
                                options={type}
                                value={searchType}
                                placeholder="Select Tex Type"
                                onChange={(e) => {
                                  setSearchType(e);
                                }}
                              />
                            </div>
                            <div className="num me-2">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                            {/* <div className="Search me-2">
                              <Button
                                type="button"
                                onClick={getDataAll}
                                className="btn btn-search"
                              >
                                <img src={search1} className="search" alt="" />
                              </Button>
                            </div> */}

                            <div className="Search-1">
                              <button
                                type="button"
                                onClick={() => {
                                  setSearch("");
                                  setSearchType("");
                                  setReset(!reset);
                                }}
                                className="btn btn-reset"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {visible.col1 && <th className="sr">Sr. No.</th>}
                              {visible.col2 && (
                                <th className="tax-name">Tax Type</th>
                              )}
                              {visible.col3 && (
                                <th className="tax-name">Tax Percentage</th>
                              )}
                              {visible.col4 && (
                                <th className="tax-name">Status</th>
                              )}

                              {visible.col5 && (
                                <th className="active">Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && <td>{d?.tax_type?.name}</td>}
                                  {visible.col3 && <td>{d?.name}</td>}
                                  {visible.col4 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          disabled={!isAllow?.includes(59)}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}
                                  {visible.col5 && (
                                    <td>
                                      <div className="d-flex">
                                        {isAllow?.includes(59) ? (
                                          <Button
                                            // to={`/masters/tax-percentage/edit/${d?.id}`}
                                            onClick={() => handleShow1(d?.id)}
                                            type="button"
                                            className="btn btn-primary me-1"
                                          >
                                            <img
                                              src={pen}
                                              className="pen"
                                              alt=""
                                            />
                                          </Button>
                                        ) : (
                                          <></>
                                        )}

                                        {isAllow?.includes(60) ? (
                                          <button
                                            onClick={() => {
                                              showDeleteRecord(d?.id, d?.name);
                                            }}
                                            type="button"
                                            className="btn btn-danger"
                                          >
                                            <img
                                              src={basket}
                                              className="pen"
                                              alt=""
                                            />
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />

      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br />
                  Dependent Data Also Be Deleted And
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
