import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ChromePicker from "react-color";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL, getDimension, deleteData } =
    useContext(Context);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxPercentages, setPercentages] = useState([]);
  const [attributes, setAttribute] = useState([]);
  const [subAttributes, setSubAttribute] = useState([]);

  const id = props.show;
  const [role, setRole] = useState([]);
  const [data, setData] = useState();

  const [user, setUser] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      console.log("user :-", response?.data);
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  console.log("isDisabled ", isDisabled);

  useEffect(() => {
    console.log("user?.id == ", user?.id);
    console.log("i am here ", user);
    if (user?.id === 1) {
      // setIsDisabled(true);
    }
  }, [user]);

  const validationRules = user?.id === 1 ? { required: "Take an Action" } : {};

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();
  const imagesFile = watch("images");
  const imageFile = watch("image1");
  // console.log("imagesFile:", imagesFile);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variants", // Name of your field array
  });

  const [variantsError, setVariantsError] = useState("");

  // useEffect(() => {
  //   register("variants", {
  //     validate: (value) => {
  //       const isValid = value && value.length > 0;
  //       setVariantsError(isValid ? "" : "At least one variant is required");
  //       return isValid;
  //     },
  //   });
  // }, [register]);

  const [variantColors, setVariantColors] = useState(
    Array.from({ length: fields.length }, () => "#ffffff")
  );

  // Function to update variantColor for a specific index
  const setVariantColor = (index, color) => {
    setVariantColors((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = color;
      return newColors;
    });
  };
  const [showPicker, setShowPicker] = useState(false);

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("name", data?.name);
      DataToSend.append("short_description", data?.short_description);
      DataToSend.append("manufacturer", data?.manufacturer);
      DataToSend.append("vender", data?.vender);
      DataToSend.append("about", data?.about);
      DataToSend.append("benifits", data?.benifits);
      DataToSend.append("color", data?.color);
      DataToSend.append("storage", data?.storage);
      DataToSend.append("image1", data?.image1[0]);
      DataToSend.append("category_id", data?.category_id?.value);
      DataToSend.append("sub_category_id", data?.sub_category_id?.value);
      DataToSend.append(
        "child_sub_category_id",
        data?.child_sub_category_id?.value
      );
      DataToSend.append("brand_id", data?.brand_id?.value);
      DataToSend.append("tax_type_id", data?.tax_type_id?.value);
      DataToSend.append("tax_percentage_id", data?.tax_percentage_id?.value);
      for (let i = 0; i < data?.images?.length; i++) {
        DataToSend.append(`images`, data?.images[i]);
        console.log("image of i", data?.images[i]);
      }
      if (isDisabled && data?.stage) {
        DataToSend.append(`stage`, data?.stage?.value);
      }

      // Append variants
      // const variants = [];
      // data.variants.forEach((variant, index) => {
      //   variants.push({
      //     // attribute_id: variant.attribute_id?.value,
      //     // sub_attribute_id: variant.sub_attribute_id?.value,
      //     variant_id: variant?.id,
      //     v_name: variant.v_name,
      //     color: variant.color,
      //     unit: variant.unit,
      //     mrp: variant.mrp,
      //     sku: variant.sku,
      //     s_price: variant.s_price,
      //   });

      //   let inputImages = document.getElementById(`variantImages${index}`);
      //   console.log("variant images", inputImages.files);
      //   const files = inputImages.files;
      //   for (let i = 0; i < files.length; i++) {
      //     DataToSend.append(`images${index}`, files[i])
      //   }

      // });

      // DataToSend.append("variants", JSON.stringify(variants));
      const response = await postData(`/product/${id}`, DataToSend);
      console.log("response", response);
      console.log("data to send", DataToSend);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImage = async (imageId) => {
    try {
      const response = await deleteData(`/product/product-image/${imageId}`);
      if (response.success) {
        console.log("Image deleted successfully");
      } else {
        console.error("Failed to delete image");
      }
      GetEditData();
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };
  const deleteLocalImage = async (index) => {
    const updatedImagesFile = [...imagesFile];
    updatedImagesFile.splice(index, 1);
    setValue("images", updatedImagesFile);
  };

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");

    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);

    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllChildSubCategory = async (id) => {
    const response = await getData(`/allchildsubcategories/${id}`);

    if (response?.success) {
      setChildSubCategories(
        await Select2Data(response?.data, "child_sub_category_id")
      );
    }
  };

  const GetAllBrands = async () => {
    const response = await getData("/allbrands");

    if (response?.success) {
      setBrands(await Select2Data(response?.data, "brand_id"));
    }
  };

  const GetAllTaxTypes = async () => {
    const response = await getData("/all-tax-type");

    if (response?.success) {
      setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
    }
  };

  const GetAllTaxPercentages = async (id) => {
    const response = await getData(`/all-tax-percentage/${id}`);

    if (response?.success) {
      setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
    }
  };

  const GetAllAttributes = async () => {
    const response = await getData(`/all-attribute`);

    if (response?.success) {
      setAttribute(await Select2Data(response?.data, "attribute_id"));
    }
  };

  const GetAllSubAttributes = async (id) => {
    const response = await getData(`/all-sub-attribute/${id}`);

    if (response?.success) {
      setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
    }
  };

  console.log(getValues("images[0]"), "llll");

  const GetEditData = async () => {
    const response = await getData(`/product/${id}`);
    setData(response);
    setData(response?.data);
    reset(response?.data);
    if (response?.data?.stage === "Approved") {
      // setIsDisabled(true)
    }
    console.log(response?.data);
  };

  useEffect(() => {
    GetEditData();
    GetAllCategory();
    GetAllBrands();
    GetAllTaxTypes();
    GetAllAttributes();
  }, []);

  const handleColorChange = (color) => {
    setValue("color", color.hex);
  };

  // const handleProceed = async (data) => {
  //   try {
  //     const dataToSend = new FormData();
  //     dataToSend.append("stage", data?.stage?.value)
  //     // dataToSend.append("email", data?.email)
  //     const response = await postData(`/product/request/${id}`, dataToSend);
  //     console.log(response);
  //     if (response?.success) {
  //       await setShowModal({ code: response.code, message: response.message });
  //     } else {
  //       await setShowModal({ code: response.code, message: response.message });
  //     }
  //     setTimeout(() => {
  //       setShowModal(0);
  //       props.handleClose();
  //     }, 1000);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Edit Product</Card.Title>
                <hr />
                <Container>
                  <Form
                    // onSubmit={() => handleSubmit(onSubmit)}
                    role="form"
                    // className="stateclass"
                  >
                    <Row>
                      {/* <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Category</Form.Label>
                          <Controller
                            name="category_id" // name of the field
                            {...register("category_id", {
                              required: "Select Category",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.category_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={categories}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value); // Update Controller's value
                                  GetAllSubCategory(selectedOption.value);
                                  setValue("category_id", selectedOption);
                                  setValue("sub_category_id", "");
                                  setValue("child_sub_category_id", "");

                                }}
                              />
                            )}
                          />
                          {errors.category_id && (
                            <span className="text-danger">
                              {errors.category_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Sub Category</Form.Label>
                          <Controller
                            name="sub_category_id" // name of the field
                            {...register("sub_category_id", {
                              required: "Select Sub Category",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.sub_category_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={subCategories}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value); // Update Controller's value
                                  GetAllChildSubCategory(selectedOption.value);
                                  setValue("sub_category_id", selectedOption);
                                  setValue("child_sub_category_id", "");
                                }}
                              />
                            )}
                          />
                          {errors.sub_category_id && (
                            <span className="text-danger">
                              {errors.sub_category_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="row justify-content-center mb-2">
                          <Form.Label>Child Sub Category</Form.Label>
                          <Controller
                            name="child_sub_category_id" // name of the field
                            {...register("child_sub_category_id", {
                              required: "Select Child Sub Category",
                            })}
                            control={control}
                            render={({ field }) => (
                              <Select
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.child_sub_category_id
                                      ? "red"
                                      : baseStyles,
                                  }),
                                }}
                                {...field}
                                options={childSubCategories}

                              />
                            )}
                          />
                          {errors.child_sub_category_id && (
                            <span className="text-danger">
                              {errors.child_sub_category_id.message}
                            </span>
                          )}
                        </Row>
                      </div>
                    </Col> */}

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Name .."
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Name is required",
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      {/* <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Short Description</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="short_description"
                                  placeholder="Short Description"
                                  // maxLength={40}
                                  className={classNames("", {
                                    "is-invalid": errors?.short_description,
                                  })}
                                  {...register("short_description", {
                                    required: "Short Description is required",
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.short_description && (
                                <span className="text-danger">
                                  {errors.short_description.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col> */}

                      <Col lg={6}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                Description
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="short_description"
                                  control={control}
                                  rules={{
                                    required: "short description is required",
                                  }}
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field?.value || ""}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid":
                                          !!errors.short_description,
                                      })}
                                      placeholder="Description"
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.short_description && (
                                <span className="text-danger">
                                  {errors.short_description.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      {/* <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Pick a color</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Controller
                                  name="color"
                                  control={control}
                                  defaultValue=""
                                  disabled={isDisabled}
                                  render={({ field }) => (
                                    <>
                                      <Form.Control
                                        {...field}
                                        type="text"
                                        placeholder="Color"
                                        className={classNames("", {
                                          "is-invalid": errors?.color,
                                        })}
                                        readOnly
                                        onClick={() => setShowPicker(!showPicker)}
                                        value={getValues("color")}
                                      />
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: '50%',
                                          right: '8px',
                                          transform: 'translateY(-50%)',
                                          width: '20px',
                                          height: '20px',
                                          borderRadius: '50%',
                                          border: '1px solid #ccc',
                                          backgroundColor: getValues("color"),
                                        }}
                                      ></div>
                                    </>
                                  )}
                                />
                              </InputGroup>
                              {showPicker && (
                                <ChromePicker
                                  color={getValues("color")}
                                  onChange={handleColorChange}
                                />
                              )}
                              {errors?.color && (
                                <span className="text-danger">
                                  {errors.color.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col> */}

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Unit</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="unit"
                                  placeholder="unit"
                                  className={classNames("", {
                                    "is-invalid": errors?.unit, // Updated error handling
                                  })}
                                  {...register("unit", {
                                    required: "unit is required",
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.unit && (
                                <span className="text-danger">
                                  {errors.unit.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      {/* MRP */}
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>MRP</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="mrp"
                                  placeholder="mrp"
                                  className={classNames("", {
                                    "is-invalid": errors?.mrp, // Updated error handling
                                  })}
                                  {...register("mrp", {
                                    required: "mrp is required",
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.mrp && (
                                <span className="text-danger">
                                  {errors.mrp.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      {/* SKU */}
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>SKU</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="sku"
                                  placeholder="sku"
                                  className={classNames("", {
                                    "is-invalid": errors?.sku, // Updated error handling
                                  })}
                                  {...register("sku", {
                                    required: "sku is required",
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.sku && (
                                <span className="text-danger">
                                  {errors.sku.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      {/* Selling Prise */}
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Selling Price</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="s_price"
                                  placeholder="s_price"
                                  className={classNames("", {
                                    "is-invalid": errors?.s_price, // Updated error handling
                                  })}
                                  {...register("s_price", {
                                    required: "s_price is required",
                                    // validate: validateSellingPrice,
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.s_price && (
                                <span className="text-danger">
                                  {errors.s_price.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      {!isDisabled && (
                        <Col md={3}>
                          <div className="main-form-section mt-3">
                            <Form.Label>Image</Form.Label>

                            <Form.Group>
                              <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.image1,
                                })}
                                type="file"
                                {...register("image1", {
                                  // required: "images is required",
                                })}
                                accept="image/*"
                              />
                            </Form.Group>
                            {errors.image1 && (
                              <span className="text-danger">
                                {errors.image1.message}
                              </span>
                            )}
                          </div>
                        </Col>
                      )}

                      <Col lg={3}>
                        <div className="main-form-section mt-3">
                          <Form.Label>Image Preview</Form.Label>

                          {typeof getValues("image1") == "string" ? (
                            <div className="image-preview-container">
                              <img
                                src={IMG_URL + getValues("image1")}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "150px", height: "130px" }}
                              />
                            </div>
                          ) : (
                            imageFile &&
                            imageFile?.length > 0 && (
                              <div className="image-preview-container">
                                <img
                                  // src={URL.createObjectURL(getValues("image")[0])}
                                  src={URL?.createObjectURL(imageFile[0])}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </Col>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>Product Details</Card.Title>
                      <hr />

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Manufacturer</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="manufacturer"
                                  placeholder="manufacturer"
                                  className={classNames("", {
                                    "is-invalid": errors?.manufacturer,
                                  })}
                                  {...register("manufacturer", {
                                    required: "manufacturer is required",
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.manufacturer && (
                                <span className="text-danger">
                                  {errors.manufacturer.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>

                          <Row className="justify-content-center">
                            <Form.Label>Vender</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="vender"
                                  placeholder="vender"
                                  className={classNames("", {
                                    "is-invalid": errors?.vender,
                                  })}
                                  {...register("vender", {
                                    required: "vender is required",
                                  })}
                                  disabled={isDisabled}
                                />
                              </InputGroup>
                              {errors.vender && (
                                <span className="text-danger">
                                  {errors.vender.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      {/* <Col md={3}>
  <div className="main-form-section mt-3">
    <Row className="justify-content-center">
      <Form.Label>vender</Form.Label>
      <Form.Group>
        <InputGroup>
          <Form.Control
            type="text"
            name="vender"
            placeholder="vender"
            className={classNames("", {
              "is-invalid": errors?.vender,
            })}
            {...register("vender", {
              required: "vender is required",
            })}
          />
        </InputGroup>
        {errors.vender && (
          <span className="text-danger">
            {errors.vender.message}
          </span>
        )}
      </Form.Group>
    </Row>
  </div>
</Col> */}

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Brand</Form.Label>
                            <Controller
                              name="brand_id" // name of the field
                              {...register("brand_id", {
                                required: "Select Brand",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.brand_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={brands}
                                  isDisabled={isDisabled}
                                />
                              )}
                            />
                            {errors.brand_id && (
                              <span className="text-danger">
                                {errors.brand_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Tax Type</Form.Label>
                            <Controller
                              name="tax_type_id" // name of the field
                              {...register("tax_type_id", {
                                required: "Select Tax Type",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.tax_type_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={taxTypes}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value); // Update Controller's value
                                    GetAllTaxPercentages(selectedOption.value);
                                    setValue("tax_type_id", selectedOption);
                                  }}
                                  isDisabled={isDisabled}
                                />
                              )}
                            />
                            {errors.tax_type_id && (
                              <span className="text-danger">
                                {errors.tax_type_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Tax Percentage</Form.Label>
                            <Controller
                              name="tax_percentage_id" // name of the field
                              {...register("tax_percentage_id", {
                                required: "Select Tax Percentage",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.tax_percentage_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={taxPercentages}
                                  isDisabled={isDisabled}
                                />
                              )}
                            />
                            {errors.tax_percentage_id && (
                              <span className="text-danger">
                                {errors.tax_percentage_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                About
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="about"
                                  control={control}
                                  rules={{ required: "About is required" }}
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field?.value || ""}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.about,
                                      })}
                                      placeholder="About"
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.about && (
                                <span className="text-danger">
                                  {errors.about.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Row className="mt-5 pb-3">
                        <div className="d-flex justify-content-center">
                          <Link>
                            <CancelButton
                              name={"cancel"}
                              handleClose={props.handleClose}
                            />
                          </Link>
                          {/* {data?.stage !== "Approved" && */}
                          <Button
                            name={"save"}
                            onClick={handleSubmit(onSubmit)}
                            type="button"
                          >
                            save
                          </Button>
                          {/* } */}
                        </div>
                      </Row>
                    </Row>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
