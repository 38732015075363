import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Nav from "react-bootstrap/Nav";

import Tab from "react-bootstrap/Tab";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL, getDimension, deleteData } =
    useContext(Context);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxPercentages, setPercentages] = useState([]);

  const id = props.show;
  const [role, setRole] = useState([]);
  const [data, setData] = useState();

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const GetEditData = async () => {
    const response = await getData(`/order/view/${id}`);
    setData(response);
    setData(response?.data);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Nav variant="pills" className="edit-tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Order Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Products</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">User Details</Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Form
                          // onSubmit={() => handleSubmit(onSubmit)}
                          role="form"
                          // className="stateclass"
                        >
                          <Row>
                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Invoice Number</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.invoice_number}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Payment Mode</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.payment_mode}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Amount</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.amount}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            {data?.remark && (
                              <Col md={3}>
                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Group>
                                      <Form.Label>Remark</Form.Label>
                                      <InputGroup>
                                        <Form.Control
                                          disabled
                                          name="invoice_number"
                                          value={data?.remark}
                                          placeholder="Invoice Number"
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            )}

                            {data?.reason && (
                              <Col md={3}>
                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Group>
                                      <Form.Label>Reason</Form.Label>
                                      <InputGroup>
                                        <Form.Control
                                          disabled
                                          name="invoice_number"
                                          value={data?.reason}
                                          placeholder="Reason"
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            )}

                            {data?.delivery_date && (
                              <Col md={3}>
                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Group>
                                      <Form.Label>Delivery date</Form.Label>
                                      <InputGroup>
                                        <Form.Control
                                          disabled
                                          name="invoice_number"
                                          value={data?.delivery_date}
                                          placeholder="Delivery date"
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>
                            )}
                        
                              <Col md={3}>
                                <div className="main-form-section mt-3">
                                  <Row className="justify-content-center">
                                    <Form.Group>
                                      <Form.Label>Delivery Address</Form.Label>
                                      <InputGroup>
                                        <Form.Control
                                          disabled
                                          name="invoice_number"
                                          value={`${data?.users_address?.flat_home_bldg || ''},${data?.users_address?.col_land_strt || ''},${data?.users_address?.city?.name || ''},${data?.users_address?.state?.name || ''}`}

                                          placeholder="Delivery Address"
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Product Quantity</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.orders_details?.length}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                        

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Order Status</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.order_status?.order_status}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <div className="main-form-section mt-5"></div>
                          </Row>
                        </Form>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Form
                          // onSubmit={() => handleSubmit(onSubmit)}
                          role="form"
                          // className="stateclass"
                        >
                          <Row>
                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Product Name</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.orders_details
                                          .map((detail) => detail.product?.name)
                                          .join(", ")}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Short Description</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.orders_details
                                          .map(
                                            (detail) =>
                                              detail.product?.short_description
                                          )
                                          .join(", ")}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Unit</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.orders_details
                                          .map((detail) => detail.product?.unit)
                                          .join(", ")}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>SKU</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.orders_details
                                          .map((detail) => detail.product?.sku)
                                          .join(", ")}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>MRP</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.orders_details
                                          .map((detail) => detail.product?.mrp)
                                          .join(", ")}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Selling Price</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="invoice_number"
                                        value={data?.orders_details
                                          .map(
                                            (detail) => detail.product?.s_price
                                          )
                                          .join(", ")}
                                        placeholder="Invoice Number"
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>Image</Form.Label>
                                    <InputGroup>
                                      {data?.orders_details.map(
                                        (detail, index) => (
                                          <div key={index} className="mb-3">
                                            <img
                                              src={
                                                IMG_URL + detail.product?.image1
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            <div className="main-form-section mt-5"></div>
                          </Row>
                        </Form>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Form
                          // onSubmit={() => handleSubmit(onSubmit)}
                          role="form"
                          // className="stateclass"
                        >
                          <Row>
                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>User Name</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="first_name"
                                        value={data?.user?.first_name}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>User Email</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="email"
                                        value={data?.user?.email}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>User Contact Number</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="contact_no"
                                        value={data?.user?.contact_no}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>User Date OF Birth</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="dob"
                                        value={data?.user?.dob}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Group>
                                    <Form.Label>User Gender</Form.Label>
                                    <InputGroup>
                                      <Form.Control
                                        disabled
                                        name="gender"
                                        value={data?.user?.gender}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <div className="main-form-section mt-5"></div>
                          </Row>
                        </Form>
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
