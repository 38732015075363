import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import { InputGroup, Form } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import LogoutModal from "../common/Logout_Modal/Logout_Modal";

library.add(fas);

function Header(props) {
  const [modalShow, setModalShow] = useState(false);
  const {
    setUserData,
    setUsertype,
    setSignin,
    usertype,
    isSidebarOpen,
    setSidebarOpen,
    toggleSidebar,
    getData,
    signin,
  } = useContext(Context);

  const navigate = useNavigate();

  const LogOut = async () => {
    Cookies.remove("purevia_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    setUser(response?.data);
  };
  useEffect(() => {
    GetUserData();
  }, []);

  return (
    <>
      <section className="header">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Navbar.Brand>
            <div className="header-text">
              <div className="bars" onClick={toggleSidebar}>
                <FaBars />
              </div>
              <h1>{props.title}</h1>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div className="header-icons">
                <div className="icon dropdown ">
                  <div className="text-center dropdown-toggle">
                    <p className="mb-0 logout_content">{user?.first_name}</p>
                    <p>{user?.type}</p>
                  </div>
                </div>

                <div>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => setModalShow(true)}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-right-from-bracket"
                        className="text-danger"
                      />
                      <span className="ms-3">Logout</span>
                    </a>
                  </li>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <LogoutModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onLogout={() => {
            LogOut();
            setModalShow(false);
          }}
        />
      </section>
    </>
  );
}

export default Header;
