import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import ColorPickerInput from "../../common/ColorPicker";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, getDimension } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [headerColor, setHeaderColor] = useState("");
  const [footerColor, setFooterColor] = useState("");

  const GetEditData = async () => {
    const response = await getData(`/home/app-setup/${id}`);
    reset(response?.data);
    setHeaderColor(response?.data?.header_color);
    setFooterColor(response?.data?.footer_color);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("contact_no", data?.contact_no);
      finalData.append("address", data?.address);
      finalData.append("email", data?.email);
      finalData.append("header_color", headerColor);
      finalData.append("footer_color", footerColor);
      finalData.append("image", data?.image[0]);
      const response = await postData(`/home/app-setup/${id}`, finalData);
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Setup
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Banner"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Contact No.</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="contact_no"
                          placeholder="Contact No"
                          minLength={10} // minLength should be used instead of min
                          maxLength={10} // MaxLength should be used here
                          className={classNames("", {
                            "is-invalid": errors?.contact_no,
                          })}
                          {...register("contact_no", {
                            required: "Contact No is required",
                            pattern: {
                              value: /^[0-9]{10}$/, // Regex pattern to enforce exactly 10 digits
                              message: "Contact No must be exactly 10 digits",
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.contact_no && (
                        <span className="text-danger">
                          {errors.contact_no.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Address</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="address"
                          placeholder="Address"
                          className={classNames("", {
                            "is-invalid": errors?.address,
                          })}
                          {...register("address", {
                            required: "Address is required",
                          })}
                        />
                      </InputGroup>
                      {errors.address && (
                        <span className="text-danger">
                          {errors.address.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Email</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="email" // Use type="email" for email validation
                          name="email"
                          placeholder="Email"
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Regex pattern for email validation
                              message: "Enter a valid email address",
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Header Color</Form.Label>
                    <Form.Group>
                      <ColorPickerInput
                        value={headerColor}
                        onChange={(color) => setHeaderColor(color)}
                        errors={errors} // Pass errors to the component if needed
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Footer Color</Form.Label>
                    <Form.Group>

                      <ColorPickerInput
                        value={footerColor}
                        onChange={(color) => setFooterColor(color)}
                        errors={errors} // Pass errors to the component if needed
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    <Form.Label className="text-left">Image</Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          // required: "Image is required",
                          // validate: async (value) => {
                          //   if (typeof value !== "string") {
                          //     const fileTypes = ["jpg", "png", "jpeg"];
                          //     const fileType = value[0].name?.split(".")[1];
                          //     if (!fileTypes.includes(fileType)) {
                          //       return `please upload a valid file format. (${fileTypes})`;
                          //     }
                          //     const sizes = await getDimension(value[0]);
                          //     if (
                          //       sizes.width !== 1296 &&
                          //       sizes.height !== 465
                          //     ) {
                          //       return "Image width and height must be 1296 px and 465 px";
                          //     }
                          //   }
                          // },
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col className="main-form-section mt-3">
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Image Preview</Form.Label>

                    {typeof getValues("image") == "string" ? (
                      <div
                        className="image-preview-container mt-3"
                        style={{ marginLeft: "110px" }}
                      >
                        <img
                          src={IMG_URL + getValues("image")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "648px", height: "198px" }}
                        />
                      </div>
                    ) : (
                      imageFile &&
                      imageFile?.length > 0 && (
                        <div
                          className="image-preview-container mt-3"
                          style={{ marginLeft: "110px" }}
                        >
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "648px", height: "198px" }}
                          />
                        </div>
                      )
                    )}
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
